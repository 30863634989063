<template>
  <van-popup 
    :value="value"
    :overlayStyle="{ background: 'rgba(20, 21, 30, 0.6)' }"
    :style="{ width: '100%', maxHeight: '80%', minHeight: '35%', background: '#1E232D', padding: '0 10px' }"  
    :transition-appear=true
    position="bottom" 
    duration="0.5"
    @input="val => this.$emit('input', val)"
    @open="init"
    @close='closePop'
    round
  >

  <div class="input-wrap">
    <div class="input-title"> 
      <div class="title-left">认证失败</div>
      <div class="title-right" @click="handleConfirm">{{ $t('common.sure') }}</div>
    </div>
    <div class="content">
      {{ message }}
    </div>


  </div>

</van-popup>


</template>

<script>
export default {
  name:'',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    initMsg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      message: ''
    }
  },
  methods: {
    init() {
      this.message = this.initMsg
    },
    closePop() {
      this.$emit('input', false)
    },
    handleConfirm() {
      this.closePop()
    }
  },
  mounted() {
    this.init()
  },
  created() {

  }
}
</script>


<style lang="scss" scoped>
.input-wrap {
  margin: 20px 0;
  padding: 0 10px;

  .input-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    .title-right {
      color: #FD2084;
    }
  }

  .content {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

}

.van-popup--round {
  border-radius: 10px 10px 0 0;
}
</style>