<!-- 用户背景墙 -->
<template>
  <div class="img-box">
    <img src="@/assets/img/icon/feedback.svg" class="feedback-img" v-if="isFeedback" />
    <div class="feedback-btn" :class="{'btn-en': this.lang==='EN'}" v-if="isFeedback" @click="handleFeedback">
        {{ $t('userDetail.report') }}
    </div>
    
    <div class="verify" v-if="showAuthState">
      <!-- 10:未上传,20:已上传(未认证),30:审核通过,40:审核不通过 -->
      <template v-if="authStatus === 10 || authStatus === 0">
        <img src="@/assets/img/user/verifie-failed.svg">
        <span>未认证</span>
      </template>
      <template v-else-if="authStatus === 20">
        <img src="@/assets/img/user/verifie-ing.svg">
        <span>认证中</span>
      </template>
      <template v-else-if="authStatus === 30">
        <img src="@/assets/img/user/verifie-success.svg">
        <span>已认证</span>
      </template>
      <template v-else-if="authStatus === 40">
        <div class="failBtn" @click="showReasonPop">
          <p><img src="@/assets/img/user/verifie-failed.svg"></p>
          <p>认证失败,查看原因</p>
        </div>
      </template>
    </div>
    <div class="mask"></div>
    <van-image class="bg-img" :src="bgImg" fit="cover" />
    <Header bgColor="transparent" />

    <VerifyFailPop v-model="showReason" :initMsg="reason" />
  </div>
</template>

<script>

import Header from '../Header.vue'
import { queryUserInfoById } from "@/service/user";
import bus from "@/utils/bus";
import VerifyFailPop from "@/components/user/VerifyFailPop.vue";
import {queryVerifyFailReason} from "@/service/base";
import $gbUtils from "@/utils/gbUtils";

export default {
  name: 'UserBackground',
  components: { Header, VerifyFailPop },
  props: {
    userId: {
      type: String,
      require: true,
      default: ''
    }
  },
  data() {
    return {
      appDom: null,
      bgImg: '',
      authStatus: null,
      showAuthState: false,
      showReason: false,
      reason: ''
    }
  },
  watch: {
    userId(newVal, oldVal) {
      this.init()
    },
    lang(oldValue, newValue) {
      console.log('oldLang,newLang', oldValue, newValue)
    }
  },
  methods: {
    async init() {
      if (this.userId) {
        const resp = await queryUserInfoById({ userId: this.userId })
        if (resp.success) {
          let bgImage = resp.data.backgroundPhoto ? resp.data.backgroundPhoto : resp.data.headPhoto
          if (bgImage) {
            this.bgImg = this.$gbUtils.formatImgUrl(bgImage)
          } else {
            this.bgImg = require('../../assets/img/user-default-war.png')
          }
          if (resp.data.sex === 2) {
            this.showAuthState = true
            this.authStatus = resp.data.status
            bus.$emit('updateAuthStatus', this.authStatus)
            if (this.authStatus === 40) {
              // 查询审核失败原因
              const resp = await queryVerifyFailReason(null)
              if (resp.code !== 200) return
              this.reason = resp.data[0].refuseReason || ''
            }
          }
        }
      }
    },
    appScroll() {
      var headerDom = document.getElementById('header');
      const appScrollTop = this.appDom.scrollTop;
      if (appScrollTop > 110) {
        headerDom.style.backgroundColor = '#14151e'
      } else {
        headerDom.style.backgroundColor = 'transparent'
      }
    },
    handleFeedback() {
      this.$router.push({ path: "/feedback", query: { userId: this.userId } })
    },
    showReasonPop() {
      this.showReason = true
    },
    closeReasonPop() {
      this.showReason = false
    }
  },
  created() {
  },
  computed: {
    $gbUtils() {
      return $gbUtils
    },
    lang() {
      return this.$store.state.lang
    },
    isFeedback() {
      return this.$gbUtils.isMan() && this.userId !== this.$gbUtils.getLoginUserId()
    }
  },
  mounted() {
    this.init()
    this.appDom = document.getElementById('app');
    this.appDom.addEventListener('scroll', this.appScroll);
  },
  beforeDestroy() {
    this.appDom.removeEventListener('scroll', this.appScroll);
  },
}
</script>

<style scoped lang="scss">
.img-box {
  position: relative;
  width: 100%;
  max-height: 230px;
  overflow: hidden;

  .feedback-img {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 66px;
    right: 75px;
    z-index: 2;
  }
  .feedback-btn {
    position: absolute;
      top: 60px;
      right: 20px;
      border: 1px solid #fff;
      border-radius: 12px;
      width: 85px;
      height: 28px;
      text-align: end;
      line-height: 28px;
      font-size: 10px;
      padding-right: 6px;
      z-index: 2;
  }

  .verify {
    position: absolute;
    bottom: 50px;
    right: 20px;
    z-index: 2;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }
    span {
      font-size: 12px;
      margin-top: 2px;
    }
    .failBtn {
      display: flex;
      align-items: center;
      border-radius: 16px;
      background: rgba(82, 71, 116, 0.70);
      padding: 5px 8px;
      font-size: 12px;
      :first-child {
        padding-top: 1px;
      }
    }
  }

  .bg-img {
    width: 100%;
    height: 230px;
  }

  .mask {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .reason-wrap {
    .reason-box {
      width: 100%;
      height: 100%;
      background: #1E232D;
      display: flex;
    }
  }

}
</style>
