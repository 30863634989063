<!-- 用户信息卡片 -->
<template>
  <div class="card-box">
    <div class="user-avatar">
      <img :src="formatHeadPhoto(userInfo.headPhoto)" />
      <template v-if="userInfo.sex === 1">
        <img src="@/assets/img/icon/man.png" class="user-sex" >
      </template>
      <template v-else>
        <img src="@/assets/img/icon/female.png" class="user-sex" >
      </template>
      <div class="user-nickname">{{ userInfo.nickname }}</div>
      <div class="user-like" v-if="type==='1'">
        <div class="like-box">
          <div @click="actionLike">
            <template v-if="like">
              <img src="@/assets/img/user/select-like.svg" />
            </template>
            <template v-else>
              <img src="@/assets/img/user/no-select-like.svg" />
            </template>
          </div>
          <div @click="actionNotLike">
            <template v-if="disLike">
              <img src="@/assets/img/user/select-dislike.svg" />
            </template>
            <template v-else>
              <img src="@/assets/img/user/no-select-dislike.svg" />
            </template>
          </div>
        </div>
      </div>
      <!-- <div class="feedback-btn" :class="{'btn-en': this.lang==='EN'}" v-if="isFeedback" @click="handleFeedback">
        {{ $t('userDetail.report') }}
      </div>
      <img src="@/assets/img/icon/feedback.svg" class="feedback-img" v-if="isFeedback" @click="handleFeedback" /> -->
    </div>
    <div class="user-info web2">
      <!-- 用户卡片展示-->
      <template v-if="type === '1'">
        <div class="base-info">
          <div class="left">
            <img src="@/assets/img/icon/position.svg" />
            <div>{{ userInfo.locationName }} </div>
          </div>
          <div class="right">
            <img src="@/assets/img/icon/greenDot.svg" />
            <div>{{ formatTime(userInfo.loginDate) }} 在线</div>
          </div>
        </div>
        <div class="chat-btns">
          <div class="chat w48"
            @click="() => $emit('handleSocialClick')">
            <img src="@/assets/img/icon/telegram.svg" />
            {{ $t('userDetail.socialAccount') }}
          </div>

          <div class="gift w48" v-if="!isLoginUser && $gbUtils.isMan()"
            @click="() => $emit('handleReviewClick')">
            {{ $t('userDetail.review') }}
          </div>
        </div>
      </template>

      <!-- 用户卡片，个人中心信息修改-->
      <template v-if="type === '2'">
        <div class="user-btns" :class=" isAuth ? '': 'b3' ">
          <div class="user-btn" :class=" isAuth ? '': 'b3-item' " @click="() => $emit('handleEditClick')">
            <img src="@/assets/img/icon/edit.svg" />
            <div>{{ $t('userDetail.editProfile') }}</div>
          </div>
          <div class="user-btn" :class=" isAuth ? '': 'b3-item' " @click="() => $emit('handleEditPwd')">
            <img src="@/assets/img/icon/password.svg" />
            <div>{{ $t('userDetail.updatePwd') }}</div>
          </div>
          <template v-if="!isAuth || this.authStatus === 0" >
            <div class="user-btn b3-item" @click="handleSubmitVideo">
              <img src="@/assets/img/icon/video.svg" />
              <div>{{ $t('userDetail.submitVideo') }}</div>
            </div>
          </template>

        </div>
      </template>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import bus from "@/utils/bus";
import $gbUtils from "@/utils/gbUtils";
import { userLike,userDislike} from "@/service/feedback"


export default {
  name: "",
  props: {
    userInfo: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: "1",
    },
    like: {
      type: Boolean,
      default: false
    },
    disLike: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      authStatus: 100,
    }
  },
  watch: {
    lang(oldValue, newValue) {
      console.log('oldLang,newLang', oldValue, newValue)
    },
  },
  computed: {
    $gbUtils() {
      return $gbUtils
    },
    ...mapState(['lang']),
    isAuth() {
      if (this.authStatus === 100 || this.authStatus === 0) return true
      return !(this.authStatus === 10 || this.authStatus === 40); // 10 未上传 40 认证失败
    },
    isSign() {
      return this.$gbUtils.isSign();
    },
    isLoginUser() {
      return this.userInfo._id === this.$gbUtils.getLoginUserId()
    },
    isFeedback() {
      return this.$gbUtils.isMan() && this.userInfo._id !== this.$gbUtils.getLoginUserId()
    }
  },
  methods: {
    ...mapActions([
      "changeShowLoginPop",
    ]),
    async init() {
      
    },
    formatTime(str) {
      return this.$gbUtils.formatOnlineTime(str, this.lang)
    },
    formatHeadPhoto() {
      if (this.userInfo.headPhoto) return this.$gbUtils.formatImgUrl(this.userInfo.headPhoto)
      return require("@/assets/img/user-default.png")
    },
    handleSubmitVideo() {
      this.$gbUtils.handleToPage('/uploadVideo')
    },
    handleFeedback() {
      this.$router.push({ path: "/feedback", query: { userId: this.userInfo._id } })
    },
    async actionLike() {
      if (!this.isSign) {
        this.changeShowLoginPop(true)
        return
      }
      console.log('actionLike')
      this.like = !this.like
      if (this.like && this.disLike) {
        this.disLike = false
      }
      const params = {
        resourceId: this.userInfo._id,
        active: this.like ? 1 : 0
      }
      await userLike(params)
    },
    async actionNotLike() {
      if (!this.isSign) {
        this.changeShowLoginPop(true)
        return
      }
      console.log('actionNotLike')
      this.disLike = !this.disLike
      if (this.disLike && this.like) {
        this.like = false
      }
      const params = {
        resourceId: this.userInfo._id,
        active: this.disLike ? 1 : 0
      }
      await userDislike(params)
    }
  },
  mounted() {
    bus.$on('updateAuthStatus', (status) => {
      if (this.type === '2') {
        console.log('userCard updateAuthStatus', status)
        this.authStatus = status
      }
    })
    this.init()
  }
};
</script>

<style scoped lang="scss">
.card-box {
  background: #14151e;
  position: relative;
  border-radius: 15px 15px 0 0;
  margin-top: -30px;
  z-index: 1;

  .user-avatar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 92px;
    position: absolute;
    top: -45px;
    left: 0;
    padding: 0 20px;

    .user-sex {
      border: 0;
      width: 14px;
      height: 14px;
      margin-top: 50px;
      margin-left: -18px;
    }


    .user-nickname {
      font-size: 20px;
      color: #fff;
      margin-left: 10px;
      font-weight: 600;
      position: relative;
      img {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 30px;
        left: -30px;
      }
    }

    .user-like {
      width: 80px;
      height: 18px;
      justify-content: end;
      position: absolute;
      right: 20px;
      top: 55px;
      .like-box {
        display: flex;
        justify-content: space-between;
        img {
          width: 18px;
          height: 18px;
          display: inline-block;
          margin-left: 10px;
        }
        
      }
      
    }
    .feedback-btn {
      position: absolute;
      top: 60px;
      right: 20px;
      border: 1px solid #fff;
      border-radius: 12px;
      width: 85px;
      height: 28px;
      text-align: end;
      line-height: 28px;
      font-size: 10px;
      padding-right: 6px;

    }
    .btn-en {
      padding-right: 15px;
    }
    .feedback-img {
      position: absolute;
      top: 62px;
      right: 75px;
      width: 28px;
      height: 24px;
    }

    >img {
      height: 90px;
      width: 90px;
      border-radius: 100%;
      border: 5px solid #14151e;
    }
  }

  .user-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 130px;

    .base-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 50px 20px 0 20px;
      line-height: 15px;

      >div {
        display: flex;
        width: 50%;
        align-items: center;
      }

      .left {
        justify-content: flex-start;

        div {
          color: #ECECEC;
          font-size: 14px;
        }

        :last-child {
          margin-left: 5px
        }

        img {
          margin-right: 4px;
          width: 12px;
          height: 12px;
          margin-top: -3px;
        }
      }

      .right {
        justify-content: flex-end;

        div {
          font-size: 10px;
          color: #fff;
        }

        img {
          margin-right: 4px;
        }
      }

    }

    .chat-btns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 36px;
      padding: 0 20px;

      div {
        height: 36px;
        width: 100px;
        text-align: center;
        line-height: 36px;
        font-size: 13px;
        font-weight: 600;
        color: #fff;
        border-radius: 4px;
      }

      .w48 {
        width: 48% !important;
      }

      .chat {
        width: 120px;
        background-color: #8A73D0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 5px;
        }
      }

      .gift {
        background-color: #FD2084;
      }

      .tg {
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
    }

    .user-addr {
      width: 90%;
      height: 40px;
      background-color: #2C3341;
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 60px;
      border-radius: 4px;

      img {
        height: 15px;
        width: 18px;
      }

      .address {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        margin-left: 8px;
      }

      .copy {
        width: 52px;
        height: 28px;
        line-height: 28px;
        border: 1px solid #fff;
        text-align: center;
        border-radius: 4px;
        margin-right: 5px;
      }
    }

    .user-btns {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 38px;
      margin: 20px 0;

      .user-btn {
        width: 47%;
        height: 38px;
        background-color: #2C3341;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 13px;
        margin-right: 5px;
        :last-child {
          margin-right: 0;
        }

        img {
          height: 12px;
          width: 12px;
          margin-right: 6px;
        }
      }
    }

    .b3 {
      justify-content: space-around;
    }
    .b3-item {
      // width: 31% !important;
    }
    .pt40 {
      padding-top: 40px;
    }
  }
  .web2 {
    align-items: end;
  }
}

.profit {
  margin-bottom: 18px;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    backdrop-filter: blur(10px);
  }

  .profit-info {
    text-align: center;
    padding: 0 53px;
    box-sizing: border-box;
    // border-bottom: 2px dotted rgba(255, 255, 255, 0.20);
    padding-bottom: 15px;

    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #fff;
      margin: -24px auto 10px auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .user-name {
      font-size: 18px;
      font-weight: bold;
    }

    .user-address {
      width: 100%;
      display: flex;
      align-items: center;
      background: rgba($color: #c7bec5, $alpha: 0.5);
      border-radius: 4px;
      padding: 0 6px;
      box-sizing: border-box;
      margin-top: 10px;

      img {
        width: 23px;
        height: 23px;
      }

      .address {
        flex: 1;
        padding: 0 5px;
        box-sizing: border-box;
      }
    }

    .info-boxs {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 10px 0;

      .box {
        border-radius: 3px;
        border: 1px solid #e8e8f1;
        font-size: 12px;
        color: #e8e8f1;
        padding: 1px 5px;
      }
    }

    .login-boxs {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;

      .box {
        display: flex;
        align-items: center;

        img {
          width: 13px;
          height: 13px;
          margin-right: 5px;
        }

        &:last-child {
          padding-right: 5px;
        }
      }
    }
  }

  .social-boxs {
    padding: 10px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
    font-size: 14px;
    border-top: 2px dotted rgba(255, 255, 255, 0.2);

    .box {
      margin-right: 15px;
      border-radius: 4px;
      background: #8e8cf6;
      padding: 5px 12px;

      &:nth-of-type(2) {
        background: #c50cb3;
      }

      &:last-child {
        background: #ff2570;
      }
    }
  }

  .edit-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .edit-box {
    margin-top: 15px;
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 139px;
    padding: 6px 10px 5px 10px;
    cursor: pointer;
    font-size: 14px;
  }
}
</style>
